import { ExpandMore } from "@mui/icons-material";
import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import { formatDateInIST } from "../../utils/DateFormatter";
import OrderList from "./orderList.js/orderList";
const ActionNeededOrders = ({ data }) => {
  const [isRequestAvailable, setIsRequestAvailable] = useState(true);
  const CON = useContext(GlobalContext);
  const [allRequest, setAllRequest] = useState([]);
  const lineItems = [
    {
      name: "Rice Bran Disposable Cutlery - 6 inches round Plain",
      price: "700.00",
      variant_id: "44407652778229",
      total_discount: "",
      variant_title: "6 inches round Plain",
      quantity: 1,
    },
    {
      name: "Rice Bran Disposable Cutlery - 6 inches round Plain",
      price: "700.00",
      variant_id: "44407652778229",
      total_discount: "",
      variant_title: "6 inches round Plain",
      quantity: 1,
    },
  ];
  const getActiveRequest = async () => {
    const res = await CON.hitAxios({
      path: `/api/agentActionRequired/getActiveRequest`,
      post: false,
      admin: false,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.data.success) {
      setAllRequest(res.data.data);
    }
  };
  useEffect(() => {
    getActiveRequest();
  }, []);
  return (
    <Box mt={2} borderRadius={4} bgcolor={"white"} p={1}>
      {/* {allRequest.length > 0 ? (
        allRequest.map((ell) => {
          return (
            <Box>
              {" "}
              <Accordion defaultExpanded={false}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel3-content"
                  id="panel3-header"
                >
                  <Box
                    bgcolor={"#379a00"}
                    color={"white"}
                    padding={0.5}
                    borderRadius={1}
                  >
                    {" "}
                    Order Cancel Request | {formatDateInIST(ell.createdAt)}
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Box>
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      flexWrap={"wrap"}
                    >
                      {" "}
                      <Box fontWeight={500}>
                        {" "}
                        Order No : {ell.orderDetails.order_number}
                      </Box>
                      <Box>
                        {" "}
                        Order Date :{" "}
                        {formatDateInIST(ell.orderDetails.created_at)}
                      </Box>{" "}
                    </Box>
                    <Box> Order Value :{ell.orderDetails.total_price}</Box>
                    <Box>Line Items :</Box>
                    <Grid container gap={"5px"}>
                      {ell?.orderDetails?.line_items?.map((el) => {
                        return (
                          <Grid
                            xs={12}
                            sm={12}
                            md={5}
                            lg={5}
                            item
                            border={"2px solid #ffd7d7"}
                            padding={1}
                          >
                            <Box> {el.name}</Box>{" "}
                            <Box>
                              Price -{el.price} | Quantity - {el.quantity} |
                              Variant-
                              {el.variant_title}
                            </Box>{" "}
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Box>
                </AccordionDetails>
                <AccordionActions>
                  <Button>Discard</Button>
                  <Button>Approve</Button>
                </AccordionActions>
              </Accordion>
            </Box>
          );
        })
      ) : (
        <Box display={"flex"} justifyContent={"center"}>
          <Typography
            // variant="body2"
            fontWeight={500}
          >
            {"Currently, there is no active request."}
          </Typography>
        </Box>
      )} */}

      <OrderList
        data={data}
        setAllRequest={setAllRequest}
        getMyDetails={allRequest}
      />
    </Box>
  );
};
export default ActionNeededOrders;
