import React, { useState } from "react";
import Chart from "react-apexcharts";
import { Tooltip, Typography } from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import getRandomColor from "../../utils/RandomColorGenerator.js";

const ChatInsightsComponent = ({ chatInsightsData }) => {
  function downloadCSVFileChatInsights() {
    const convertedCSV =
      "Chat Insights,Count\n" +
      chatInsightsData
        .map(function (d) {
          delete d.interactions;
          return JSON.stringify(Object.values({ a: d.intent, b: d.count }));
        })
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");
    const dl = "data:text/csv;charset=utf-8," + convertedCSV;
    const downloadLink = document.createElement("a");
    downloadLink.href = dl;
    downloadLink.download = "chatInsightsData.csv";

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  const [chatInsights, setChatInsights] = useState({
    option: {
      plotOptions: {
        bar: {
          borderRadius: 4,
          borderRadiusApplication: "end",
          horizontal: true,
          dataLabels: {
            maxItems: 3,
            hideOverflowingLabels: true,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories:
          chatInsightsData.length > 8
            ? chatInsightsData?.map((i) => i.intent).slice(0, 8)
            : chatInsightsData?.map((i) => i.intent),
        trim: true,
      },
      yaxis: {
        tickAmount: 3,
        // trim: true
      },
      chart: {
        toolbar: {
          tools: {
            download: false,
          },
        },
      },
      colors: [() => getRandomColor()],
    },
    series: [
      {
        name: "Count",
        data:
          chatInsightsData.length > 8
            ? chatInsightsData?.map((i) => i.count).slice(0, 8)
            : chatInsightsData?.map((i) => i.count),
      },
    ],
  });

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          style={{
            fontSize: "14px",
            fontWeight: "900",
            fill: "rgb(55, 61, 63)",
            paddingLeft: "10px",
            fontFamily: "Helvetica, Arial, sans-serif",
          }}
        >
          Top Chat Insights of all channels
        </Typography>
        <Tooltip title="Download All Data">
          <Typography
            display={"flex"}
            sx={{ cursor: "pointer" }}
            onClick={downloadCSVFileChatInsights}
          >
            Export full data
            <FileDownloadOutlinedIcon />
          </Typography>
        </Tooltip>
      </div>
      <Chart
        id="basic-line-chart"
        height={"100%"}
        width={"100%"}
        toolbar={chatInsights.toolbar}
        options={chatInsights.option}
        series={chatInsights.series}
        type="bar"
      />
    </>
  );
};

export default ChatInsightsComponent;
