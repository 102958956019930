import React from "react";
import Chart from "react-apexcharts";
import getRandomColor from "../../utils/RandomColorGenerator.js";

const TotalProductAddedToCartWebsdk = ({ data, totalProductAddedToCart }) => {
  const options = {
    plotOptions: {
      bar: {
        borderRadius: 4,
        borderRadiusApplication: "end",
        horizontal: false,
      },
      theme: {
        palette: "palette1", // upto palette10
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: totalProductAddedToCart?.map((i) => i.productName),
    },
    title: {
      text: "Total Product added to cart websdk",
      align: "left",
    },
    charts: {
      theme: {
        palette: "palette1", // You can use 'palette1', 'palette2', etc., or define custom colors
        mode: "light", // You can switch between 'light' and 'dark'
      },
    },
    colors: [() => getRandomColor()],
  };

  const series = [
    {
      name: "Count",
      data: totalProductAddedToCart?.map((i) => i.count),
    },
  ];

  return (
    <Chart
      id="basic-line-chart"
      height={"100%"}
      width={"100%"}
      options={options}
      series={series}
      type="bar"
    />
  );
};

export default TotalProductAddedToCartWebsdk;
