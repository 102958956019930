import React, { useContext, useEffect, useState } from "react";
import { SmartToy, ApartmentOutlined, Close } from "@mui/icons-material";
import {
  Grid,
  Stack,
  Typography,
  Button,
  ToggleButton,
  Box,
  Tooltip,
  Select,
  MenuItem,
} from "@mui/material";

import AddTrivia from "./addTrivia";
import { GlobalContext } from "../../context/GlobalContext";
import TriviaList from "./triviaList";

const Trivia = ({ botData, setBotData, data }) => {
  const [suggestion, setSuggestion] = useState("");
  const { hitAxios } = useContext(GlobalContext);
  const [allTrivia, setAllTrivia] = useState([]);
  const [state, setState] = useState({
    addDialog: false,
    triviaData: { text: "", path: "", buttons: [], _id: "" },
    viewOnly: false,
  });
  const [isError, setError] = useState(false);
  const handleCompanyLogoChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "image/png") {
      const reader = new FileReader();
      reader.onload = () => {
        setBotData({ ...botData, companyLogo: reader.result });
      };
      reader.readAsDataURL(file);
    } else {
      setBotData({ ...botData, companyLogo: "" });
      alert("Please select a PNG file.");
    }
  };
  const checkError = (data) => {
    if (
      isNaN(data?.bottomPlacement?.replace("%", "")) ||
      !data?.bottomPlacement?.includes("%") ||
      (data?.bottomPlacement?.includes("%") &&
        data?.bottomPlacement.length == 1)
    ) {
      setError(true);
    } else if (
      isNaN(data?.rightPlacement?.replace("px", "")) ||
      !data?.rightPlacement?.includes("px") ||
      (data?.rightPlacement?.includes("px") && data?.rightPlacement.length == 2)
    ) {
      setError(true);
    } else if (
      isNaN(data?.rightPlacementMobile?.replace("px", "")) ||
      !data?.rightPlacementMobile?.includes("px") ||
      (data?.rightPlacementMobile?.includes("px") &&
        data?.rightPlacementMobile.length == 2)
    ) {
      setError(true);
    } else if (
      isNaN(data?.bottomPlacementMobile?.replace("px", "")) ||
      !data?.bottomPlacementMobile?.includes("px") ||
      (data?.bottomPlacementMobile?.includes("px") &&
        data?.bottomPlacementMobile.length == 2)
    ) {
      setError(true);
    } else if (
      data?.menuSection.contactUs &&
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data?.contactUsReceivingEmail)
    ) {
      setError(true);
    } else {
      setError(false);
    }
  };
  async function getAllTrivia() {
    const myData = JSON.parse(localStorage.getItem("myData"));
    const res = await hitAxios({
      path:
        process.env.REACT_APP_BACKEND_BASE_URL +
        `/backend/trivia/getall?userId=${myData?._id}`,
      // userData?.bots[0]?.botId,
      post: false,
      admin: false,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.status === 200) {
      setAllTrivia(res.data.data);
      // setBotData(res.data);
      // to be decided or remove this method not required
    }
  }
  useEffect(() => {
    getAllTrivia();
  }, []);
  return (
    <Grid item xs={12} p={1}>
      <Box display={"flex"} justifyContent={"flex-end"}>
        <Button
          // fullWidth
          variant="contained"
          color="primary"
          size="md"
          style={{ margin: "3, 0, 2", textTransform: "none" }}
          onClick={() =>
            setState({
              ...state,
              addDialog: true,
              viewOnly: false,
              triviaData: { trivia: "", path: "", buttons: [], _id: "" },
            })
          }
        >
          Create new
        </Button>
      </Box>
      <AddTrivia
        state={state}
        setState={setState}
        botData={botData}
        getAllTrivia={getAllTrivia}
      />
      <TriviaList
        data={data}
        allTrivia={allTrivia}
        setState={setState}
        getAllTrivia={getAllTrivia}
        state={state}
      />
    </Grid>
  );
};

export default Trivia;
