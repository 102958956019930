import { Box, Typography, Divider, Chip } from "@mui/material";
import React, { useContext, useEffect } from "react";
import OneChat from "./messages/OneChat";
import { InboxContext } from "../../../../context/InboxContext";
function getRandomLightColor() {
  // Generate random RGB values
  const r = Math.floor(200 + Math.random() * 55); // 200-255
  const g = Math.floor(200 + Math.random() * 55); // 200-255
  const b = Math.floor(200 + Math.random() * 55); // 200-255

  // Convert to hexadecimal format
  const color = `#${r.toString(16).padStart(2, "0")}${g
    .toString(16)
    .padStart(2, "0")}${b.toString(16).padStart(2, "0")}`;

  return color;
}

const ChattingHere = ({ data, value, chatConvoData }) => {
  useEffect(() => {}, [chatConvoData]);
  return (
    <Box>
      {chatConvoData?.length > 1 && Array.isArray(chatConvoData[0])
        ? chatConvoData?.map((i, index) => {
            let color = getRandomLightColor();
            return (
              <>
                {" "}
                <Divider bgColor={"#d1d1d3"}>
                  <Chip
                    label={
                      index != chatConvoData.length - 1
                        ? ` Session ${index + 1}`
                        : "Current Session"
                    }
                    size="small"
                  />
                </Divider>
                {i.map((d) => {
                  return (
                    <OneChat
                      value={value}
                      chatConvoData={chatConvoData}
                      key={d.timestamp}
                      data={data}
                      i={d}
                      bgText={
                        index != chatConvoData.length - 1 ? "#dedede" : ""
                      }
                      // bgColor={index != chatConvoData.length - 1 ? "#d1d1d3" : ""}
                    />
                  );
                })}
              </>
            );
          })
        : chatConvoData?.length == 1 &&
          chatConvoData[0]?.map((i, key) => {
            return (
              <OneChat
                value={value}
                chatConvoData={chatConvoData}
                key={key}
                data={data}
                i={i}
                bgColor={""}
              />
            );
          })}
    </Box>
  );
};

export default ChattingHere;
