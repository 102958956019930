import React from "react";
import Dashboard from "./dashboard/Dashboard";
import ManagePlans from "./plans/ManagePlans";
import ManageUsers from "./manage-users/ManageUsers";
import PaymentGateway from "./payment-gateway/PaymentGateway";
import PartnersLogo from "./partners-logo/PartnersLogo";
import Faq from "./faq/Faq";
import ManagePage from "./manage-page/ManagePage";
import Testimonial from "./testimonial/Testimonial";
import Orders from "./orders/Orders";
import ContactForm from "./contactForm/ContactForm";
import AppConfig from "./appCOnfig/AppConfig";
import SMTP from "./SMTP/SMTP";
import Translation from "./translation/Translation";
import ErrorLogs from "./ErrorLogs/errorlogs";

const DashboardPage = (props) => {
  return (
    <div>
      {props.page === 0 && <Dashboard />}
      {props.page === 1 && <ManagePlans />}
      {props.page === 2 && <ManageUsers />}
      {props.page === 3 && <PaymentGateway />}
      {props.page === 4 && <PartnersLogo />}
      {props.page === 5 && <Faq />}
      {props.page === 6 && <ManagePage />}
      {props.page === 7 && <Testimonial />}
      {props.page === 8 && <Orders />}
      {props.page === 9 && <ContactForm />}
      {props.page === 10 && <AppConfig />}
      {props.page === 11 && <SMTP />}
      {props.page === 12 && <Translation />}
      {props.page === 13 && <ErrorLogs />}
    </div>
  );
};

export default DashboardPage;
