import { Box, Typography } from "@mui/material";
import React from "react";
import WordcloudComponent from "./WordCloudComponent";

function UserIntentsComponent({ userIntentData }) {
  const [hoverdValue, setHoverdValue] = React.useState(null);
  const [tooltipPosition, setTooltipPosition] = React.useState({ x: 0, y: 0 });
  const handleMouseMove = (event) => {
    setTooltipPosition({ x: event.layerX, y: event.layerY });
  };
  React.useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);
  return (
    <div>
      <Typography
        style={{
          cursor: "pointer",
          paddingLeft: "10px",
          fontWeight: "bold",
          fontStyle: "14px",
        }}
      >
        Chat keywords by volume of all channels
      </Typography>
      {hoverdValue && (
        <Box
          position="absolute"
          left={tooltipPosition.x + 10}
          top={tooltipPosition.y + 10}
          bgcolor="#22a454"
          color="black"
          borderRadius={1}
          pointerEvents="none"
          sx={{
            border: "1px solid #e3e3e3",
            background: "rgba(255, 255, 255, .96)",
          }}
        >
          <div
            class="apexcharts-tooltip-title"
            style={{
              fontFamily: "Helvetica, Arial, sans-serif",
              fontSize: "12px",
              background: "#eceff1",
            }}
          >
            <span class="apexcharts-tooltip-text-y-value">Title : </span>
            <span class="apexcharts-tooltip-text-y-label">
              {" " + hoverdValue.text}
            </span>
          </div>
          <div
            class="apexcharts-tooltip-series-group apexcharts-active"
            style={{ order: 1, display: "flex" }}
          >
            {/* To-do: to find the dynamic color of hovered text
             <span
              class="apexcharts-tooltip-marker"
              style={{ backgroundColor: "rgba(69, 63, 238, 0.87)" }}
            ></span> */}
            <div
              class="apexcharts-tooltip-text"
              style={{
                fontFamily: "Helvetica, Arial, sans-serif",
                fontSize: "12px",
              }}
            >
              <div class="apexcharts-tooltip-y-group">
                <span class="apexcharts-tooltip-text-y-label">
                  Word Frequency :{" "}
                </span>
                <span class="apexcharts-tooltip-text-y-value">
                  {hoverdValue.value}
                </span>
              </div>
            </div>
          </div>
        </Box>
      )}
      <WordcloudComponent
        userIntentData={userIntentData}
        setHoverdValue={setHoverdValue}
      />
    </div>
  );
}
export default UserIntentsComponent;
