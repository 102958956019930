import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

const BotVsUser = ({ data, totalBotVsUser }) => {
  const [series, setState] = useState([0, 0, 0]);
  const options = {
    chart: {
      width: 380,
      type: "pie",
    },
    labels: ["AGENT", "BOT"],
    title: {
      text: "Query Resolve Bot Vs AGENT of all channel",
      align: "left",
    },
  };
  useEffect(() => {
    if (totalBotVsUser.length != 0) {
      setState([
        totalBotVsUser[0].documentsWithAgentMessages[0]?.count
          ? totalBotVsUser[0].documentsWithAgentMessages[0]?.count
          : 0,
        totalBotVsUser[0].documentsWithoutAgentMessages[0]?.count
          ? totalBotVsUser[0].documentsWithoutAgentMessages[0]?.count
          : 0,
      ]);
    }
  }, [totalBotVsUser]);
  return (
    <Chart
      id="basic-line-chart"
      height={"100%"}
      width={"100%"}
      options={options}
      series={series}
      labels={{ name: "count", data: series }}
      type="pie"
    />
  );
};

export default BotVsUser;
