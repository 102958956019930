import { Box, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import { DataGrid } from "@mui/x-data-grid";
import { useContext } from "react";
import { formatDateInIST } from "../../utils/DateFormatter";

const ErrorLogs = () => {
  const { hitAxios } = useContext(GlobalContext);
  const [errorList, setErrorList] = useState([]);
  const getErrorLogs = async () => {
    const res = await hitAxios({
      path: "/api/errorlogging/getAll",
      post: false,
      admin: true,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_admin"),
    });
    if (res.data.success) {
      setErrorList(res.data.data);
    }
  };
  useEffect(() => {
    getErrorLogs();
  }, []);
  return (
    <Box mt={2}>
      <Box height={"48vh"}>
        <DataGrid
          getRowId={(row) => row._id}
          rows={errorList}
          columns={[
            {
              headerName: "Time",
              field: "createdAt",
              flex: 1,
              renderCell: (dataa) => {
                return (
                  <Tooltip title={"Time"}>
                    {formatDateInIST(dataa.row?.createdAt)}
                  </Tooltip>
                );
              },
            },
            {
              headerName: "Api",
              field: "apiName",
              flex: 1,
            },
            {
              headerName: "Error Msg",
              field: "errorMsg",
              flex: 1,
            },
            {
              headerName: "Status Code",
              field: "statusCode",
              flex: 1,
              renderCell: (dataa) => {
                return (
                  <Tooltip title={dataa.row?.actualMetaTemplet?.category}>
                    {dataa.row?.actualMetaTemplet?.category}
                  </Tooltip>
                );
              },
            },
          ]}
          pageSize={50}
          rowsPerPageOptions={[50]}
          checkboxSelection={false}
          // components={{
          //     Toolbar: CustomToolbar
          // }}
          sx={{
            boxShadow: 0,
            borderRadius: 4,
            border: 0,
            fontSize: 13,
            padding: 2,
          }}
        />
      </Box>
    </Box>
  );
};

export default ErrorLogs;
