import { Box, LinearProgress } from "@mui/material";
import React from "react";
import DashboardHeader from "./DashboardHeader";
import { TranslateContext } from "../context/TranslateContext";
import PhoneBook from "./phonebook/PhoneBook";
import ChatFlow from "./chat-flow/ChatFlow";
import Inbox from "./inbox/Inbox";
import { getUser } from "../utils/GetMe";
import { GlobalContext } from "../context/GlobalContext";
import Chatbot from "./chatbot/Chatbot";
import BroadCast from "./broadcast/BroadCast";
import Dashboard from "./dashboard/Dashboard";
import Analytics from "./analytics/Analytics";
import ApiAccess from "./apiAccess/ApiAccess";
import Channels from "./channels/Channels";
import PaymentHistory from "./paymentHistory/PaymentHistory";
import ContactUsDetails from "./contactus/contactus";
import Viewabandoneddata from "./viewabandoneddata/viewabandoneddata";
import Automation from "./Automation/Automation";
import Agents from "./agents";
import { getNumberOfDaysFromTimestamp } from "../utils/DateFormatter";
import WhatsappIntegrationpage from "./whatsappIntegration/whatsappIntegration";
import Orders from "./orders/Orders";
import LeadsWebSdk from "./leads/leads";

const UserDashboard = (props) => {
  const { data } = React.useContext(TranslateContext);
  const [page, setPage] = React.useState(null);
  const CON = React.useContext(GlobalContext);

  let search = window.location.search;
  let params = new URLSearchParams(search);
  let pageRoutValue = params.get("page");

  async function updateUser() {
    // const user = await getUser();
    // CON.setData({ ...CON.data, userData: user });
  }
  const userData = JSON.parse(localStorage.getItem("myData"));
  const validateNewUser = () => {
    if (userData.store == undefined) {
      return false;
    } else if (!userData.bots || userData.bots.length == 0) {
      return true;
    } else {
      return false;
    }
  };

  const checkDisabled = () => {
    if (!userData.store || !userData.bots || userData.bots.length == 0) {
      return true;
    } else {
      return false;
    }
  };
  const afterMetaRegistredRoutes = () => {
    if (
      !userData.store ||
      !userData.bots ||
      userData.bots.length == 0 ||
      !userData.isMetaRegistred ||
      userData.isMetaRegistred == false
    ) {
      return true;
    } else {
      return false;
    }
  };
  let disabledPages = {
    phonebook: true,
    // "chatbot-flow": !userData.store,
    "chatbot-flow": true,
    inbox: true,
    chatbot: true,
    broadcast: true,
    channels: true,
    "api-access": true,
    "pay-history": true,
    analytics: true,
    automation: true,
    dashboard: true,
  };
  if (userData.userType != "agent") {
    const days = getNumberOfDaysFromTimestamp(userData.planExpiresAt);
    if (days < 1) {
      disabledPages = {
        phonebook: true,
        // "chatbot-flow": !userData.store,
        "chatbot-flow": true,
        inbox: true,
        chatbot: true,
        broadcast: true,
        channels: true,
        "api-access": true,
        "pay-history": false,
        analytics: true,
        automation: true,
        dashboard: true,
        orders: true,
      };
    } else {
      disabledPages = {
        phonebook: afterMetaRegistredRoutes(),
        // "chatbot-flow": !userData.store,
        "chatbot-flow": true,
        inbox: checkDisabled(),
        chatbot: !userData.store,
        broadcast: checkDisabled(),
        channels: checkDisabled(),
        "api-access": afterMetaRegistredRoutes(),
        "pay-history": checkDisabled(),
        analytics: checkDisabled(),
        automation: checkDisabled(),
        dashboard: validateNewUser(),
        orders: checkDisabled(),
      };
    }
  } else {
    const days = getNumberOfDaysFromTimestamp(userData.planExpiresAt);
    if (days < 1) {
    } else {
      userData.permissionArray.map((el) => {
        if (disabledPages[el.moduleName]) {
          disabledPages[el.moduleName] = el.permission ? false : true;
        }
      });
    }
  }
  React.useEffect(() => {
    setPage(pageRoutValue || "dashboard");
  }, [pageRoutValue]);

  React.useEffect(() => {
    if (userData.store && userData.bots.length === 0) setPage("chatbot");
    else setPage(pageRoutValue || "dashboard");
    updateUser();
  }, []);

  return (
    <div>
      {CON.getMyDetails ? (
        <Box minHeight={"100vh"} bgcolor={"#f1f1f1"}>
          <DashboardHeader
            page={page}
            setPage={setPage}
            data={data}
            disabledPages={disabledPages}
          />
          {page === "" && (
            <Dashboard page={page} setPage={setPage} data={data} />
          )}
          {page === "dashboard" && (
            <Dashboard page={page} setPage={setPage} data={data} />
          )}
          {page === "analytics" && (
            <Analytics page={page} setPage={setPage} data={data} />
          )}
          {page === "phonebook" && !disabledPages["phonebook"] && (
            <PhoneBook data={data} />
          )}
          {page === "chatbot-flow" && !disabledPages["chatbot-flow"] && (
            <ChatFlow data={data} />
          )}
          {page === "inbox" && !disabledPages["inbox"] && <Inbox data={data} />}
          {page === "chatbot" && !disabledPages["chatbot"] && (
            <Chatbot data={data} />
          )}
          {page === "broadcast" && !disabledPages["braodcast"] && (
            <BroadCast data={data} />
          )}
          {page === "channels" && !disabledPages["channels"] && (
            <Channels data={data} />
          )}
          {page === "api-access" && !disabledPages["api-access"] && (
            <ApiAccess data={data} />
          )}
          {page === "pay-history" && !disabledPages["pay-history"] && (
            <PaymentHistory data={data} />
          )}
          {page === "contact-us" && !disabledPages["contact-us"] && (
            <ContactUsDetails data={data} userData={userData} />
          )}
          {page === "leads" && !disabledPages["leads"] && (
            <LeadsWebSdk data={data} userData={userData} />
          )}
          {page === "automation" && !disabledPages["automation"] && (
            <Automation data={data} userData={userData} />
          )}
          {page === "agents" && !disabledPages["automation"] && (
            <Agents data={data} userData={userData} />
          )}
          {page === "whatsappIntegration" &&
            !disabledPages["whatsappIntegration"] && (
              <WhatsappIntegrationpage data={data} userData={userData} />
            )}
          {page === "orders" && !disabledPages["orders"] && (
            <Orders data={data} userData={userData} />
          )}
          {page === "viewabandoneddata" &&
            !disabledPages["viewabandoneddata"] && (
              <Viewabandoneddata data={data} userData={userData} />
            )}
        </Box>
      ) : (
        <LinearProgress />
      )}
    </div>
  );
};

export default UserDashboard;
