import {
  Grid,
  Stack,
  Typography,
  Button,
  ToggleButton,
  Box,
  Tooltip,
  Select,
  MenuItem,
  Dialog,
} from "@mui/material";
import { BiBot } from "react-icons/bi";
import { BsRobot } from "react-icons/bs";
import { TbMessageDots } from "react-icons/tb";
import { BiUser } from "react-icons/bi";
import {
  AiOutlineQuestionCircle,
  AiFillInfoCircle,
  AiFillPlusCircle,
  AiFillDelete,
} from "react-icons/ai";
import { TfiHeadphoneAlt, TfiCommentsSmiley } from "react-icons/tfi";
import { Ri24HoursLine } from "react-icons/ri";
import { LuMessagesSquare } from "react-icons/lu";
import Switch from "@mui/material/Switch";

import { MuiColorInput } from "mui-color-input";
import TextFieldNew from "../../components/TextFieldNew";
import { useEffect, useState } from "react";
import CloseBtn from "../../context/CloseBtn";
import { useContext } from "react";
import { GlobalContext } from "../../context/GlobalContext";
const AddTrivia = ({ setState, state, botData, getAllTrivia }) => {
  const [isError, setError] = useState(false);
  const CON = useContext(GlobalContext);
  const [buttonText, setButtonText] = useState("");
  const [viewOnly, setViewOnly] = useState(false);
  const [states, setStates] = useState({
    trivia: "",
    path: "",
    buttons: [],
    _id: "",
  });

  const handleSumitTrivia = async () => {
    if (states.trivia == "" || states.path == "") {
      CON.setData({
        ...CON.data,
        snack: true,
        snack_msg: "Please filled require details.",
        snack_success: false,
      });
      setTimeout(() => {
        CON.setData({
          ...CON.data,
          snack: false,
          snack_msg: "",
          snack_success: false,
        });
      }, 4000);
      return;
    }
    const res = await CON.hitAxios({
      path: process.env.REACT_APP_BACKEND_BASE_URL + "/backend/trivia",
      post: true,
      admin: false,
      obj: {
        userId: JSON.parse(localStorage.getItem("myData"))._id,
        pagePath: states.path,
        aiSuggestion: false,
        trivia: states.trivia,
        buttons: states.buttons,
      },
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.data.success) {
      await getAllTrivia();
      setState({ ...state, addDialog: false });
      //   setBotData(res.data);
      // to be decided or remove this method not required
    }
  };
  const handleUpdateTrivia = async () => {
    if (states.trivia == "" || states.pagePath == "") {
      CON.setData({
        ...CON.data,
        snack: true,
        snack_msg: "Please filled require details.",
        snack_success: false,
      });
      setTimeout(() => {
        CON.setData({
          ...CON.data,
          snack: false,
          snack_msg: "",
          snack_success: false,
        });
      }, 4000);
      return;
    }
    const res = await CON.hitAxios({
      path: process.env.REACT_APP_BACKEND_BASE_URL + "/backend/trivia",
      put: true,
      admin: false,
      obj: {
        userId: JSON.parse(localStorage.getItem("myData"))._id,
        pagePath: states.path,
        aiSuggestion: false,
        triviaData: {
          trivia: states.trivia,
          buttons: states.buttons,
          _id: states._id,
        },
      },
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.data.success) {
      await getAllTrivia();
      setState({ ...state, addDialog: false });
      //   setBotData(res.data);
      // to be decided or remove this method not required
    }
  };
  useEffect(() => {
    setStates(state.triviaData);
    setViewOnly(state.viewOnly);
  }, [state]);
  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={state.addDialog}
      onClose={() => setState({ ...state, addDialog: false })}
      PaperProps={{
        style: { borderRadius: 10 },
      }}
    >
      <Box p={2}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography fontWeight={600}>Add Trivia</Typography>

          <Box display={"flex"} gap="30px" alignItems={"center"}>
            <CloseBtn
              onClick={() => setState({ ...state, addDialog: false })}
            />
          </Box>
        </Stack>
        <Stack direction="column" spacing={2} sx={{ mt: 2 }}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            gap="20px"
          >
            <TextFieldNew
              fullWidth
              value={states.trivia}
              placeholder="Did you know ...."
              onChange={(e) => {
                setStates({ ...states, trivia: e.target.value });
              }}
              label="Trivia"
            />
          </Box>

          <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
            <TextFieldNew
              fullWidth
              value={states.path}
              placeholder="/home"
              onChange={(e) => {
                setStates({ ...states, path: e.target.value });
              }}
              error={false}
              label="Website path"
              helperText={"Example : /home"}
            />
          </Stack>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            gap="20px"
          >
            <span style={{ whiteSpace: "nowrap" }}>Action Button</span>
            <Select
              fullWidth
              style={{ borderRadius: "5px" }}
              value={buttonText}
              onChange={(e) => {
                setButtonText(e.target.value);
              }}
            >
              <MenuItem value={"Show products"}>Show products</MenuItem>
              <MenuItem value={"Let's discuss"}>Let's discuss</MenuItem>
            </Select>
            <AiFillPlusCircle
              fontSize={"35px"}
              color="#22a454"
              onClick={() => {
                if (buttonText.length != "") {
                  setStates({
                    ...states,
                    buttons: [...states.buttons, { text: buttonText }],
                  });
                  setButtonText("");
                }
              }}
            />
          </Box>
          <Box display={"flex"} flexWrap={"wrap"}>
            {states?.buttons.map((el, index) => {
              return (
                <Box
                  display={"flex"}
                  justifyContent={"space-around"}
                  alignItems={"center"}
                  border={"1px solid  #c8c8c8"}
                  //   padding={"10px"}
                  margin={"2%"}
                  gap={"10px"}
                  borderRadius={"10px"}
                >
                  <Button>{el.text}</Button>
                  <AiFillDelete
                    cursor={"pointer"}
                    marginRight="5px"
                    onClick={() => {
                      let newArray = [...states.buttons];
                      newArray.splice(index, 1);
                      setStates({
                        ...states,
                        buttons: newArray,
                      });
                    }}
                  />
                </Box>
              );
            })}
          </Box>
          <Stack direction="row" spacing={2}>
            {!viewOnly ? (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                size="small"
                disabled={isError}
                style={{ margin: "3, 0, 2", textTransform: "none" }}
                onClick={handleSumitTrivia}
              >
                Submit
              </Button>
            ) : (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                size="small"
                disabled={isError}
                style={{ margin: "3, 0, 2", textTransform: "none" }}
                onClick={handleUpdateTrivia}
              >
                Update
              </Button>
            )}
          </Stack>
        </Stack>
      </Box>
    </Dialog>
  );
};
export default AddTrivia;
