import {
  Box,
  Button,
  Container,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import AgentList from "./AgentList";
import AddAgentDialog from "./createAgent/AddAgentDialog";
import TempletList from "./templetList/TempletList";
import React from "react";
import AddTempletDialog from "./addTemplet/AddTempletDialog";

const Agents = ({ data, userData }) => {
  const [templetList, setTempletList] = useState([]);
  const [agentSetting, setAgentSetting] = useState({});
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
  const initalPermission = [
    { moduleName: "dashboard", permission: 0 },
    { moduleName: "automation", permission: 0 },
    { moduleName: "pay-history", permission: 0 },
    { moduleName: "analytics", permission: 0 },
    { moduleName: "phonebook", permission: 0 },
    { moduleName: "inbox", permission: 0 },
    { moduleName: "chatbot", permission: 0 },
    { moduleName: "broadcast", permission: 0 },
    { moduleName: "channels", permission: 0 },
    { moduleName: "api-access", permission: 0 },
    { moduleName: "orders", permission: 0 },
  ];
  const CON = useContext(GlobalContext);
  const [allgents, setAllagents] = useState([]);
  const [state, setstate] = useState({
    addAgentDialog: false,
    agentData: {
      firstName: "",
      email: "",
      password: "",
      isPassEnter: true,
      isViewOnly: false,
      permissionArray: initalPermission,
    },
    addDialog: false,
    templetData: {
      url: "",
      templetFor: "Agent Notification",
      mediaType: "IMAGE",
      headerText: "",
      bodyMsg: "",
      footerMsg: "",
      btnType: "NONE",
      quickReplyTitle: "",
      actionUrl: "",
      btnTitle: "",
      phoneNum: "",
      category: "UTILITY",
    },
  });
  const checkAndAddInitialTemplet = async (e) => {
    const res = await CON.hitAxios({
      path: "/api/agent/updatesetting",
      post: true,
      admin: false,
      obj: { ...agentSetting, agentNotification: e },
    });
    if (res.data.success) {
      setAgentSetting(res.data?.data);
      if (res?.data?.data?.agentNotification) {
        if (templetList.length != 0) {
          return;
        }
        // const userId = JSON.parse(localStorage.getItem("myData"))?._id;
        // let obj = {
        //   templetType: "AGENTNOTIFICATION",
        //   userId: userId,
        //   webhookType: "",
        //   templetFor: "Agent Notification",
        //   name: "agent_notifcation",
        //   language: "en",
        //   category: "UTILITY",
        //   components: [
        //     {
        //       type: "BODY",
        //       text: "User want to chat with an agent\nCustomer Name : {{1}},\nCustomer Phone : {{2}},\nPlease connect with them . and resolve the query as earliest ",
        //       example: { body_text: [["customer_name", "customer_phone"]] },
        //     },
        //     {
        //       type: "BUTTONS",
        //       buttons: [
        //         {
        //           type: "URL",
        //           text: "Chat Now !",
        //           url: "https://app.ulai.in/user?page=inbox",
        //           example: [],
        //         },
        //       ],
        //     },
        //   ],
        // };
        // const res = await CON.hitAxios({
        //   path: "/api/user/v2/add_meta_templet",
        //   post: true,
        //   admin: false,
        //   obj: obj,
        // });
        // if (res.data.success) {
        //   getTemplets();
        // }
      }
    }
  };
  const getAllagents = async () => {
    const res = await CON.hitAxios({
      path: "/api/agent/getallagents",
      post: false,
      admin: false,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.data.success) {
      setAllagents(res.data.data);
      // getAllagents();
    }
  };
  const getAgentSetting = async () => {
    const res = await CON.hitAxios({
      path: "/api/agent/setting",
      post: false,
      admin: false,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.data.success) {
      setAgentSetting(res.data.data);
      // getAllagents();
    }
  };
  const getTemplets = async () => {
    const userData = JSON.parse(localStorage.getItem("myData"));
    const res = await CON.hitAxios({
      path: "/api/user/get_my_meta_templets",
      post: true,
      admin: false,
      obj: { userId: userData?._id, type: "AGENTNOTIFICATION" },
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.data.success) {
      setTempletList(res.data.data);
    }
  };
  useEffect(() => {
    getAllagents();
    getTemplets();
    getAgentSetting();
    const updateScreenWidth = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", updateScreenWidth);
    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []);
  return (
    <Container maxWidth="lg">
      <Box display={"flex"} justifyContent={"space-between"}>
        <Box>
          {" "}
          <Typography sx={{ mt: 2, mb: 1 }}>
            {" "}
            Agent notification
            <Switch
              {...{ inputProps: { "aria-label": "Switch demo" } }}
              checked={agentSetting?.agentNotification}
              name="askPhone"
              onChange={(e) => {
                checkAndAddInitialTemplet(e.target.checked);
              }}
            />
          </Typography>
          {agentSetting?.agentNotification == true && (
            <Grid xs={12} lg={3} sm={6} item>
              <Box
                position={"relative"}
                maxHeight={screenWidth > 899 ? "70vh" : "35vh"}
                mt={2}
                borderRadius={2}
                bgcolor={"#eae9e9"}
                p={0.5}
                boxShadow={"0px 5px 10px rgba(0, 0, 0, 0.3)"}
                overflow={"hidden"}
                overflowY="scroll"
              >
                <TempletList
                  templetList={templetList}
                  setTempletList={setTempletList}
                  getTemplets={getTemplets}
                  setState={setstate}
                  state={state}
                  data={data}
                  listTitle={"Agent Notification"}
                  screenWidth={screenWidth}
                  colorTheme={"classes1"}
                />
              </Box>
            </Grid>
          )}
        </Box>
        <Button
          onClick={() => {
            setstate({
              ...state,
              addAgentDialog: true,
              agentData: {
                firstName: "",
                email: "",
                password: "",
                isPassEnter: true,
                isViewOnly: false,
                permissionArray: initalPermission,
              },
            });
          }}
        >
          Add new agent
        </Button>
      </Box>
      <AddAgentDialog
        getAllagents={getAllagents}
        state={state}
        allgents={allgents}
        setState={setstate}
        data={data}
      />
      <AddTempletDialog
        screenWidth={screenWidth}
        state={state}
        setState={setstate}
        data={data}
        getTemplets={getTemplets}
      />
      <AgentList
        data={data}
        setState={setstate}
        state={state}
        allgents={allgents}
        getAllagents={getAllagents}
      />
    </Container>
  );
};
export default Agents;
