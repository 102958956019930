import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Chart from "react-apexcharts";
import { Box, Button, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { GlobalContext } from "../../context/GlobalContext";
import dateFormatter, { dateFormate } from "../../utils/DateFormatter";
import getRandomColor from "../../utils/RandomColorGenerator.js";
import moment from "moment";

const AbandonedCartPerDayWebsdk = ({
  data,
  totalAbandonedCart,
  abandonedCartPerDay,
}) => {
  const history = useHistory();
  const G = React.useContext(GlobalContext);
  const [date, setDate] = useState({ startDate: "", endDate: "" });
  const userData = JSON.parse(localStorage.getItem("myData"));
  const [chatState, setChartState] = useState({
    option: {
      plotOptions: {
        bar: {
          borderRadius: 4,
          borderRadiusApplication: "end",
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: abandonedCartPerDay?.map((i) => dateFormatter(i?._id)),
      },
      chart: { toolbar: { show: false } },
      title: {
        text: "Abandoned carts per day websdk",
        align: "left",
      },
      colors: [() => getRandomColor()],
    },
    series: [
      {
        name: "Count",
        data: abandonedCartPerDay?.map((i) => i?.count),
      },
    ],
  });
  async function getData(startDate, endDate) {
    const res = await G.hitAxiosOnly({
      path:
        process.env.REACT_APP_BACKEND_BASE_URL +
        "/backend/analytics/" +
        userData?._id +
        `/abandoned-carts-per-day-websdk/data?startDate=${startDate}&endDate=${endDate}`,
      post: false,
      admin: false,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.status === 200) {
      setChartState({
        option: {
          plotOptions: {
            bar: {
              borderRadius: 4,
              borderRadiusApplication: "end",
              horizontal: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            categories: res?.data?.data?.map((i) => dateFormatter(i?._id)),
          },
          chart: { toolbar: { show: false } },
          title: {
            text: "Abandoned carts per day websdk",
            align: "left",
          },
          colors: [() => getRandomColor()],
        },
        series: [
          {
            name: "Count",
            data: res?.data?.data?.map((i) => i?.count),
          },
        ],
      });
    }
  }
  const downloadCSVFileAbondonedCart = async () => {
    const res = await G.hitAxios({
      path: "/api/view_data/abandoned_cart_websdk",
      post: true,
      admin: false,
      obj: { userId: userData._id },
    });
    if (res?.data?.success && res?.data?.data?.length > 0) {
      const convertedCSV =
        "Total Price,Cart Id,Customer Id,Product Details,Subtotal Price,Date\n" +
        res?.data?.data
          .map(function (itm) {
            return JSON.stringify(
              Object.values({
                a: itm.totalPrice ?? "",
                b: "" + itm.storeCartId ?? "" + "",
                c: itm.customerId ?? "",
                d: itm?.productDetails[0]?.productName ?? "",
                e: itm.subtotalPrice ?? "",
                f: itm.createdAt
                  ? moment(itm.createdAt).format("DD, MM YYYY")
                  : "",
              })
            );
          })
          .join("\n")
          .replace(/(^\[)|(\]$)/gm, "");

      const dl = "data:text/csv;charset=utf-8," + convertedCSV;
      const downloadLink = document.createElement("a");
      downloadLink.href = dl;
      downloadLink.download = "viewAbandonedData.csv";

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  useEffect(() => {
    setDate({
      startDate: dateFormate(
        new Date(new Date() - 1000 * 60 * 60 * 24 * parseInt(30))
      ),
      endDate: dateFormate(new Date()),
    });
    setChartState({
      option: {
        plotOptions: {
          bar: {
            borderRadius: 4,
            borderRadiusApplication: "end",
            horizontal: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: abandonedCartPerDay?.map((i) => dateFormatter(i?._id)),
        },
        chart: { toolbar: { show: false } },
        title: {
          text: "Abandoned carts per day websdk",
          align: "left",
        },
        colors: [() => getRandomColor()],
      },
      series: [
        {
          name: "Count",
          data: abandonedCartPerDay?.map((i) => i?.count),
        },
      ],
    });
  }, [abandonedCartPerDay]);
  return (
    <Box>
      <Box
        display={"flex"}
        flexDirection={"flex-end"}
        justifyContent={"flex-end"}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box
            components={["DatePicker", "DatePicker", "Button"]}
            display={"flex"}
            justifyContent={"flex-end"}
            gap="5px"
          >
            <DatePicker
              label="Start Date"
              inputFormat="YYYY-MM-DD"
              renderInput={(props) => (
                <TextField
                  size="small"
                  InputProps={{ style: { borderRadius: 10 } }}
                  {...props}
                />
              )}
              value={date.startDate}
              onChange={(newValue) => {
                setDate({ ...date, startDate: dateFormate(newValue.$d) });
                getData(dateFormate(newValue.$d), date.endDate);
              }}
            />
            <DatePicker
              label="End Date"
              inputFormat="YYYY-MM-DD"
              renderInput={(props) => (
                <TextField
                  size="small"
                  InputProps={{ style: { borderRadius: 10 } }}
                  {...props}
                />
              )}
              value={date.endDate}
              onChange={(newValue) => {
                setDate({ ...date, endDate: dateFormate(newValue.$d) });
                getData(date.startDate, dateFormate(newValue.$d));
              }}
            />
            <Button
              variant="contained"
              type="submit"
              color="primary"
              size="small"
              startIcon={<FileDownloadOutlinedIcon />}
              style={{ margin: "3, 0, 2", textTransform: "none" }}
              onClick={downloadCSVFileAbondonedCart}
            >
              Export Data
            </Button>
          </Box>
        </LocalizationProvider>
      </Box>
      <Chart
        id="basic-line-chart"
        height={"100%"}
        width={"100%"}
        options={chatState.option}
        series={chatState.series}
        type="bar"
      />
    </Box>
  );
};

export default AbandonedCartPerDayWebsdk;
