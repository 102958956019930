import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Button, IconButton, Switch, Tooltip } from "@mui/material";
import {
  CheckCircle,
  CheckCircleOutline,
  CheckOutlined,
  Delete,
  DeleteOutline,
  HourglassTopOutlined,
  ThumbDownOffAlt,
  ThumbUpAltOutlined,
  ThumbDownOffAltOutlined,
} from "@mui/icons-material";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import { GlobalContext } from "../../../context/GlobalContext";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { formatDateInIST } from "../../../utils/DateFormatter";

const OrderList = ({ data, getMyDetails, setAllRequest }) => {
  const history = useHistory();
  const [hide, setHide] = React.useState(
    localStorage.getItem("hide_templet_header") ? true : false
  );
  const { hitAxios, setMyDetails } = React.useContext(GlobalContext);

  async function discardRequest(id) {
    if (window.confirm(data.AUS)) {
      const res = await hitAxios({
        path: "/api/agentActionRequired/discardRequest",
        post: true,
        admin: false,
        obj: {
          email: JSON.parse(localStorage.getItem("myData")).email,
          reqId: id,
        },
      });
      if (res.data.success) {
        setAllRequest(res.data.data);
      }
    }
  }
  async function approveRequest(id) {
    if (window.confirm(data.AUS)) {
      const res = await hitAxios({
        path: "/api/agentActionRequired/proceedRequest",
        post: true,
        admin: false,
        obj: {
          email: JSON.parse(localStorage.getItem("myData")).email,
          reqId: id,
        },
      });
      if (res.data.success) {
        setAllRequest(res.data.data);
      }
    }
  }
  async function refreshPhoneStatus(phone) {
    if (window.confirm(data.AUS)) {
      const res = await hitAxios({
        path: "/api/user/whatsapp/refreshNumber",
        post: true,
        admin: false,
        obj: { phone },
      });
      if (res.data.success) {
        setMyDetails({
          ...getMyDetails,
          whatsappPhoneNumbers: res.data.data,
        });
      }
    }
  }

  return (
    <div>
      <Box borderRadius={4} bgcolor={"white"}>
        {getMyDetails?.length > 0 ? (
          <Box height={"76vh"}>
            <DataGrid
              getRowId={(row) => row._id}
              rows={getMyDetails}
              getRowHeight={() => "auto"}
              autosizeOptions={{ includeOutliers: true }}
              columns={[
                {
                  headerName: "Req. Name",
                  field: "Req. Name",
                  flex: 0.8,
                  renderCell: (dataa) => {
                    return (
                      <Tooltip title={dataa?.row?.requestName}>
                        {dataa?.row?.requestName}
                      </Tooltip>
                    );
                  },
                },
                {
                  headerName: "Order No",
                  field: "Order No.",
                  flex: 0.3,
                  renderCell: (dataa) => {
                    return (
                      <Tooltip title={dataa.row?.orderDetails?.order_number}>
                        {dataa.row?.orderDetails?.order_number}
                      </Tooltip>
                    );
                  },
                },
                {
                  headerName: "Order Date",
                  field: "Order Date",
                  flex: 1,
                  renderCell: (dataa) => {
                    return (
                      <Tooltip
                        title={formatDateInIST(
                          dataa.row?.orderDetails.created_at
                        )}
                      >
                        {formatDateInIST(dataa.row?.orderDetails.created_at)}
                      </Tooltip>
                    );
                  },
                },
                {
                  headerName: "Amount",
                  field: "amount",
                  flex: 0.4,
                  renderCell: (dataa) => {
                    return (
                      <Tooltip title={dataa.row?.orderDetails.total_price}>
                        {dataa.row?.orderDetails.total_price}
                      </Tooltip>
                    );
                  },
                },
                {
                  headerName: "Line Items",
                  field: "Line Items",
                  // flex: 1,
                  minWidth: 250, // Optional
                  maxWidth: 350, // Optional=
                  renderCell: (dataa) => {
                    return (
                      <Box display={"flex"} flexDirection={"column"}>
                        {dataa.row?.orderDetails.line_items.map((el) => {
                          return (
                            <Box
                              border={"0.5px solid green"}
                              marginBottom={"5px"}
                              padding={"2px"}
                            >
                              {" "}
                              <Box> Name : {el.name}</Box>
                              <Box> Price : {el.price}</Box>
                              <Box> Title : {el.title}</Box>
                            </Box>
                          );
                        })}
                      </Box>
                    );
                  },
                },
                {
                  headerName: "Requet Time",
                  field: "Update Time",
                  flex: 1,
                  renderCell: (dataa) => {
                    return (
                      <Tooltip title={dataa.row?.update_time}>
                        {formatDateInIST(dataa.row?.createdAt)}
                      </Tooltip>
                    );
                  },
                },
                {
                  headerName: "Status",
                  field: "Status",
                  flex: 0.6,
                  renderCell: (dataa) => {
                    return (
                      <Tooltip title={"Status"} bgcolor="red">
                        {dataa.row?.isComplete ? "COMPLETE" : "PENDING"}
                      </Tooltip>
                    );
                  },
                },
                {
                  headerName: "Action",
                  field: "Action",
                  flex: 1,
                  renderCell: (dataa) => {
                    return (
                      <>
                        {" "}
                        <Tooltip title={"Approve"}>
                          <IconButton
                            color="success"
                            onClick={() => approveRequest(dataa.row?._id)}
                          >
                            <ThumbUpAltOutlined />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={"Discard"}>
                          <IconButton
                            onClick={() => discardRequest(dataa.row?._id)}
                            color="error"
                          >
                            <ThumbDownOffAltOutlined />
                          </IconButton>
                        </Tooltip>
                      </>
                    );
                  },
                },
              ]}
              pageSize={50}
              rowsPerPageOptions={[50]}
              checkboxSelection={false}
              // components={{
              //     Toolbar: CustomToolbar
              // }}
              sx={{
                boxShadow: 0,
                borderRadius: 4,
                border: 0,
                fontSize: 13,
                padding: 2,
              }}
            />
          </Box>
        ) : (
          ""
        )}
      </Box>
    </div>
  );
};

export default OrderList;
