import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Button, IconButton, Switch, Tooltip } from "@mui/material";
import {
  CheckCircle,
  CheckCircleOutline,
  CheckOutlined,
  Delete,
  DeleteOutline,
  HourglassTopOutlined,
  ThumbDownOffAlt,
  ThumbUpAltOutlined,
  ThumbDownOffAltOutlined,
  Visibility,
} from "@mui/icons-material";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "../../context/GlobalContext";
import { formatDateInIST } from "../../utils/DateFormatter";
import EditIcon from "@mui/icons-material/Edit";

const TriviaList = ({ data, allTrivia, getAllTrivia, setState, state }) => {
  const history = useHistory();
  const [hide, setHide] = React.useState(
    localStorage.getItem("hide_templet_header") ? true : false
  );
  const { hitAxios, setMyDetails } = React.useContext(GlobalContext);

  async function delTrivia(path, id) {
    if (window.confirm(data.AUS)) {
      const res = await hitAxios({
        path: process.env.REACT_APP_BACKEND_BASE_URL + "/backend/trivia/delete",
        post: true,
        admin: false,
        obj: {
          userId: JSON.parse(localStorage.getItem("myData"))._id,
          pagePath: path,
          triviaId: id,
        },
        token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
      });
      if (res.data.success) {
        await getAllTrivia();
        setState({ ...state, addDialog: false });
        //   setBotData(res.data);
        // to be decided or remove this method not required
      }
    }
  }
  return (
    <div>
      <Box borderRadius={4} bgcolor={"white"}>
        {allTrivia?.length > 0 ? (
          <Box height={"76vh"}>
            <DataGrid
              getRowId={(row) => row._id}
              rows={allTrivia}
              getRowHeight={() => "auto"}
              autosizeOptions={{ includeOutliers: true }}
              columns={[
                {
                  headerName: "Path",
                  field: "pagePath",
                  flex: 0.8,
                },
                {
                  headerName: "Line Items",
                  field: "Line Items",
                  // flex: 1,
                  minWidth: 250, // Optional
                  maxWidth: 350, // Optional=
                  renderCell: (dataa) => {
                    return (
                      <Box display={"flex"} flexDirection={"column"}>
                        {dataa.row?.trivias?.map((el) => {
                          return (
                            <Box
                              border={"1.5px solid green"}
                              marginBottom={"5px"}
                              padding={"2px"}
                              width={"100%"}
                            >
                              {" "}
                              <Box fontSize={"14px"}> {el?.trivia}</Box>
                              <Box display={"flex"} gap="5px">
                                {" "}
                                {el?.buttons?.map((ell) => {
                                  return (
                                    <Button
                                      // fullWidth
                                      variant="contained"
                                      color="primary"
                                      size="small"
                                      style={{
                                        width: "110px",
                                        height: "30px",
                                        fontSize: "9px",
                                      }}
                                    >
                                      {ell.text}
                                    </Button>
                                  );
                                })}
                              </Box>
                              <Box
                                display={"flex"}
                                gap={"5px"}
                                justifyContent={"center"}
                              >
                                <IconButton
                                  onClick={() => {
                                    setState({
                                      ...state,
                                      addDialog: true,
                                      triviaData: {
                                        ...el,
                                        path: dataa?.row?.pagePath,
                                      },
                                      viewOnly: true,
                                    });
                                  }}
                                  color="successs"
                                >
                                  <EditIcon />
                                </IconButton>
                                <IconButton
                                  onClick={() =>
                                    delTrivia(dataa?.row?.pagePath, el._id)
                                  }
                                  color="error"
                                >
                                  <DeleteOutline />
                                </IconButton>
                              </Box>
                            </Box>
                          );
                        })}
                      </Box>
                    );
                  },
                },
                {
                  headerName: "Updated Time",
                  field: "Update Time",
                  flex: 1,
                  renderCell: (dataa) => {
                    return (
                      <Tooltip title={dataa.row?.updatedAt}>
                        {formatDateInIST(dataa.row?.updatedAt)}
                      </Tooltip>
                    );
                  },
                },
              ]}
              pageSize={50}
              rowsPerPageOptions={[50]}
              checkboxSelection={false}
              // components={{
              //     Toolbar: CustomToolbar
              // }}
              sx={{
                boxShadow: 0,
                borderRadius: 4,
                border: 0,
                fontSize: 13,
                padding: 2,
              }}
            />
          </Box>
        ) : (
          ""
        )}
      </Box>
    </div>
  );
};

export default TriviaList;
