import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

const TotalBounceRate = ({ data, totalBounce }) => {
  const [series, setState] = useState([0, 0]);
  const options = {
    chart: {
      width: 380,
      type: "pie",
    },
    labels: ["Bounce", "Use"],
    title: {
      text: "Bounce rate websdk",
      align: "left",
    },
  };
  useEffect(() => {
    if (totalBounce.totalSessions) {
      setState([
        totalBounce.totalSessions - totalBounce.totalUseBot,
        totalBounce.totalUseBot,
      ]);
    }
  }, [totalBounce]);
  return (
    <Chart
      id="basic-line-chart"
      height={"100%"}
      width={"100%"}
      options={options}
      series={series}
      labels={{ name: "count", data: series }}
      type="pie"
    />
  );
};

export default TotalBounceRate;
