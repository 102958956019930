import { MenuItem, Select, Typography } from "@mui/material";
import React from "react";
import Chart from "react-apexcharts";
import dateFormatter from "../../utils/DateFormatter";
import getRandomColor from "../../utils/RandomColorGenerator.js";

const ProductAddedToCartPerDay = ({ data, productAddedToCartPerDay }) => {
  const [selectedDate, setSelectedDate] = React.useState(
    productAddedToCartPerDay[0]?._id
  );
  const [topProductsOneDayData, setTopProductsOneDayData] = React.useState(
    productAddedToCartPerDay[0]?.products
  );
  const options = {
    plotOptions: {
      bar: {
        borderRadius: 4,
        borderRadiusApplication: "end",
        horizontal: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: topProductsOneDayData?.map((i) => i?.productName),
    },
    title: {
      text: `Product added to cart on ${dateFormatter(selectedDate)} whatsapp`,
      align: "left",
    },
    colors: [() => getRandomColor()],
  };

  const series = [
    {
      name: "Count",
      data: topProductsOneDayData?.map((i) => i?.count),
    },
  ];

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Typography>Select Date : &nbsp;</Typography>
        <Select
          size="small"
          value={selectedDate}
          onChange={(e) => {
            setSelectedDate(e.target.value);
            setTopProductsOneDayData(
              productAddedToCartPerDay.filter(
                (itm) => e.target.value === itm?._id
              )[0]?.products
            );
          }}
          sx={{ borderRadius: "10px" }}
        >
          {productAddedToCartPerDay?.map((elm) => (
            <MenuItem value={elm._id}>{elm?._id}</MenuItem>
          ))}
        </Select>
      </div>
      <Chart
        id="basic-line-chart"
        height={"100%"}
        width={"100%"}
        options={options}
        series={series}
        type="bar"
      />
    </>
  );
};

export default ProductAddedToCartPerDay;
