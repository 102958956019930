import {
  AccountCircleOutlined,
  AutoGraph,
  AutoGraphOutlined,
  BroadcastOnHome,
  BroadcastOnHomeOutlined,
  DeveloperMode,
  DeveloperModeOutlined,
  Forum,
  ForumOutlined,
  LinkOutlined,
  Logout,
  MoreHoriz,
  PaidOutlined,
  PermContactCalendar,
  PermContactCalendarOutlined,
  PersonalVideo,
  Quickreply,
  QuickreplyOutlined,
  ReceiptLong,
  ReceiptLongOutlined,
  SmartToy,
  SmartToyOutlined,
  SpaceDashboard,
  SpaceDashboardOutlined,
} from "@mui/icons-material";
import {
  CardActionArea,
  CardMedia,
  CircularProgress,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { GlobalContext } from "../context/GlobalContext";
import ProfileDialog from "./ProfileDialog";

function DashboardHeader({ data, page, setPage, disabledPages }) {
  const { hitAxios, setMyDetails, getMyDetails } =
    React.useContext(GlobalContext);
  const [web, setWeb] = React.useState({});
  const [states, setStates] = React.useState({
    dialog: false,
    dialogType: "",
  });
  const [state, setState] = React.useState({
    drawer: false,
  });
  const [profile, setProfile] = React.useState({});

  const history = useHistory();
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  async function getProfile() {
    const res = await hitAxios({
      path: "/api/user/get_me",
      post: true,
      admin: false,
      obj: {
        email: JSON.parse(localStorage.getItem("myData"))?.email,
      },
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.data.success) {
      setMyDetails({ ...res.data.data });
    }
  }

  async function getWeb() {
    const res = await hitAxios({
      path: "/api/admin/get_web_public",
      post: false,
      admin: false,
      token_user: "",
    });
    if (res.data.success) {
      setWeb(res.data.data);
    }
  }
  const myData = JSON.parse(localStorage.getItem("myData"));
  React.useEffect(() => {
    getWeb();
    getProfile();
  }, []);

  const menuData = [
    {
      icon: (
        <SpaceDashboard
          sx={{ color: (t) => t.palette.primary.main, height: 20, width: 20 }}
        />
      ),
      iconOutlined: (
        <SpaceDashboardOutlined sx={{ color: "gray", height: 20, width: 20 }} />
      ),
      title: data.dashboard,
      slug: "dashboard",
      disabled: disabledPages["dashboard"],
    },
    {
      icon: (
        <AutoGraph
          sx={{ color: (t) => t.palette.primary.main, height: 20, width: 20 }}
        />
      ),
      iconOutlined: (
        <AutoGraphOutlined sx={{ color: "gray", height: 20, width: 20 }} />
      ),
      title: "Analytics",
      slug: "analytics",
      disabled: disabledPages["analytics"],
    },
    {
      icon: (
        <Forum
          sx={{ color: (t) => t.palette.primary.main, height: 20, width: 20 }}
        />
      ),
      iconOutlined: (
        <ForumOutlined sx={{ color: "gray", height: 20, width: 20 }} />
      ),
      title: data.inbox,
      slug: "inbox",
      disabled: disabledPages["inbox"],
    },
    {
      icon: (
        <SmartToy
          sx={{ color: (t) => t.palette.primary.main, height: 20, width: 20 }}
        />
      ),
      iconOutlined: (
        <SmartToyOutlined sx={{ color: "gray", height: 20, width: 20 }} />
      ),
      title: data.chatbot,
      slug: "chatbot",
      disabled: disabledPages["chatbot"],
    },
    {
      icon: (
        <DeveloperMode
          sx={{ color: (t) => t.palette.primary.main, height: 20, width: 20 }}
        />
      ),
      iconOutlined: (
        <DeveloperModeOutlined sx={{ color: "gray", height: 20, width: 20 }} />
      ),
      title: "Channels",
      slug: "channels",
      disabled: disabledPages["channels"],
    },
    {
      icon: (
        <Quickreply
          sx={{ color: (t) => t.palette.primary.main, height: 20, width: 20 }}
        />
      ),
      iconOutlined: (
        <QuickreplyOutlined sx={{ color: "gray", height: 20, width: 20 }} />
      ),
      title: data.chatbotFlow,
      slug: "chatbot-flow",
      disabled: disabledPages["chatbot-flow"],
    },
    {
      icon: (
        <PermContactCalendar
          sx={{ color: (t) => t.palette.primary.main, height: 20, width: 20 }}
        />
      ),
      iconOutlined: (
        <PermContactCalendarOutlined
          sx={{ color: "gray", height: 20, width: 20 }}
        />
      ),
      title: data.phonebook,
      slug: "phonebook",
      disabled: disabledPages["phonebook"],
    },
    {
      icon: (
        <BroadcastOnHome
          sx={{ color: (t) => t.palette.primary.main, height: 20, width: 20 }}
        />
      ),
      iconOutlined: (
        <BroadcastOnHomeOutlined
          sx={{ color: "gray", height: 20, width: 20 }}
        />
      ),
      title: data.broadcast,
      slug: "broadcast",
      disabled: disabledPages["broadcast"],
    },
    // {
    //   icon: (
    //     <DatasetLinked
    //       sx={{ color: (t) => t.palette.primary.main, height: 20, width: 20 }}
    //     />
    //   ),
    //   iconOutlined: (
    //     <DatasetLinkedOutlined sx={{ color: "gray", height: 20, width: 20 }} />
    //   ),
    //   title: data.apiAccess,
    //   slug: "api-access",
    //   disabled: disabledPages["api-access"],
    // },
    {
      icon: (
        <ReceiptLong
          sx={{ color: (t) => t.palette.primary.main, height: 20, width: 20 }}
        />
      ),
      iconOutlined: (
        <ReceiptLongOutlined sx={{ color: "gray", height: 20, width: 20 }} />
      ),
      title: "Automation",
      slug: "automation",
      disabled: disabledPages["automation"],
    },
    {
      icon: (
        <ReceiptLong
          sx={{ color: (t) => t.palette.primary.main, height: 20, width: 20 }}
        />
      ),
      iconOutlined: (
        <ReceiptLongOutlined sx={{ color: "gray", height: 20, width: 20 }} />
      ),
      title: "Payment History",
      slug: "pay-history",
      disabled: disabledPages["pay-history"],
    },
    {
      icon: (
        <ReceiptLong
          sx={{ color: (t) => t.palette.primary.main, height: 20, width: 20 }}
        />
      ),
      iconOutlined: (
        <ReceiptLongOutlined sx={{ color: "gray", height: 20, width: 20 }} />
      ),
      title: "Orders",
      slug: "orders",
      disabled: disabledPages["orders"],
    },
  ];

  return (
    <AppBar
      sx={{ boxShadow: 0, borderBottom: 1, borderBottomColor: "#EAEAEA" }}
      color="inherit"
      position="static"
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box maxWidth={80} sx={{ display: { xs: "none", lg: "flex" } }}>
            <CardActionArea
              onClick={() => history.push("/")}
              sx={{ borderRadius: 2 }}
            >
              <CardMedia
                component={"img"}
                sx={{ height: "100%", width: 60, borderRadius: 2 }}
                // src={`/media/${web?.logo}`}
                src="./logo512.png"
              />
            </CardActionArea>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", lg: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={() => setState({ ...state, drawer: true })}
              color="inherit"
            >
              <MoreHoriz />
            </IconButton>

            <Drawer
              key="2"
              sx={{ backdropFilter: "blur(5px)" }}
              onClose={() => setState({ ...state, drawer: false })}
              anchor="left"
              open={state.drawer}
            >
              <Box sx={{ width: 250 }} role="presentation">
                <Stack
                  spacing={2}
                  alignItems={"center"}
                  direction={"column"}
                  p={2}
                >
                  <PersonalVideo sx={{ height: 50, width: 50 }} />
                  <Typography align="center" color={"gray"}>
                    {data.requestDesktop}
                  </Typography>
                </Stack>
                <List component="nav">
                  {menuData.map((item, key) => {
                    return (
                      !item.disabled && (
                        <ListItemButton
                          key={key}
                          onClick={() => {
                            history.push(`/user?page=${item.slug}`);
                            setPage(item.slug);
                            setState({ ...state, drawer: false });
                          }}
                          sx={{
                            bgcolor:
                              page === item.slug ? "action.selected" : null,
                            borderRadius: 3,
                            height: 43,
                          }}
                        >
                          <ListItemIcon>
                            {page === item.slug ? item.icon : item.iconOutlined}
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography
                                sx={{
                                  color: (t) =>
                                    page === item.slug
                                      ? t.palette.primary.main
                                      : "gray",
                                }}
                                fontWeight={page === item.slug ? 500 : 400}
                              >
                                {item.title}
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      )
                    );
                  })}
                </List>
              </Box>
            </Drawer>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", lg: "none" } }}>
            <Box maxWidth={80}>
              <CardActionArea
                onClick={() => history.push("/")}
                sx={{ borderRadius: 2 }}
              >
                <CardMedia
                  component={"img"}
                  sx={{ height: "100%", width: 80, borderRadius: 2 }}
                  // src={`/media/${web?.logo}`}
                  src="./logo512.png"
                />
              </CardActionArea>
            </Box>
          </Box>

          <Box ml={2} sx={{ flexGrow: 1, display: { xs: "none", lg: "flex" } }}>
            <Stack direction={"row"} spacing={3}>
              {menuData.map((i, key) => {
                return (
                  !i.disabled && (
                    <Stack
                      alignItems={"center"}
                      onClick={() => {
                        history.push(`/user?page=${i.slug}`);
                        setPage(i.slug);
                      }}
                      sx={{ cursor: "pointer" }}
                      key={key}
                      direction={"row"}
                      spacing={1}
                    >
                      {page === i.slug ? i.icon : i.iconOutlined}
                      <Typography
                        sx={{
                          color: (t) =>
                            page === i.slug ? t.palette.primary.main : "gray",
                        }}
                        fontWeight={page === i.slug ? 500 : 400}
                      >
                        {i.title}
                      </Typography>
                    </Stack>
                  )
                );
              })}
            </Stack>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              {getMyDetails?.id ? (
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar>
                    {getMyDetails?.name?.slice(0, 1)?.toUpperCase()}
                  </Avatar>
                </IconButton>
              ) : (
                <CircularProgress />
              )}
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              onClose={() => setAnchorElUser(false)}
              open={Boolean(anchorElUser)}
            >
              <ProfileDialog
                states={states}
                setStates={setStates}
                data={data}
              />

              <Box width={250}>
                {/* <CardActionArea
                  onClick={() => {
                    setStates({
                      ...states,
                      dialog: true,
                      dialogType: "API_META",
                    });
                  }}
                  sx={{ p: 1 }}
                >
                  <Stack direction={"row"} alignItems={"center"} spacing={2}>
                    <MapsUgcOutlined
                      sx={{ color: (t) => t.palette.primary.main }}
                    />
                    <Typography fontWeight={500} variant="body2">
                      {data.metaApiKeys}
                    </Typography>
                  </Stack>
                </CardActionArea> */}

                <CardActionArea
                  onClick={() => {
                    setStates({
                      ...states,
                      dialog: true,
                      dialogType: "PROFILE",
                    });
                  }}
                  sx={{ p: 1 }}
                >
                  <Stack direction={"row"} alignItems={"center"} spacing={2}>
                    <AccountCircleOutlined
                      sx={{ color: (t) => t.palette.primary.main }}
                    />
                    <Typography fontWeight={500} variant="body2">
                      {data.profile}
                    </Typography>
                  </Stack>
                </CardActionArea>

                <CardActionArea
                  onClick={() => {
                    setStates({
                      ...states,
                      dialog: true,
                      dialogType: "SUBSCRIPTION",
                    });
                  }}
                  sx={{ p: 1 }}
                >
                  <Stack direction={"row"} alignItems={"center"} spacing={2}>
                    <PaidOutlined
                      sx={{ color: (t) => t.palette.primary.main }}
                    />
                    <Typography fontWeight={500} variant="body2">
                      {data.subscription}
                    </Typography>
                  </Stack>
                </CardActionArea>
                {/* <CardActionArea
                  onClick={() => {
                    setStates({
                      ...states,
                      dialog: true,
                      dialogType: "SHORT_URL",
                    });
                  }}
                  sx={{ p: 1 }}
                >
                  <Stack direction={"row"} alignItems={"center"} spacing={2}>
                    <LinkOutlined
                      sx={{ color: (t) => t.palette.primary.main }}
                    />
                    <Typography fontWeight={500} variant="body2">
                      Shorten URLs
                    </Typography>
                  </Stack>
                </CardActionArea> */}
                {myData?.userType != "agent" && (
                  <CardActionArea
                    onClick={() => {
                      history.push(`/user?page=agents`);
                    }}
                    sx={{ p: 1 }}
                  >
                    <Stack direction={"row"} alignItems={"center"} spacing={2}>
                      <LinkOutlined
                        sx={{ color: (t) => t.palette.primary.main }}
                      />
                      <Typography fontWeight={500} variant="body2">
                        Agent
                      </Typography>
                    </Stack>
                  </CardActionArea>
                )}

                <CardActionArea
                  onClick={() => {
                    localStorage.removeItem(
                      process.env.REACT_APP_TOKEN + "_user"
                    );
                    localStorage.clear();
                    history.push("/user/login");
                  }}
                  sx={{ p: 1 }}
                >
                  <Stack direction={"row"} alignItems={"center"} spacing={2}>
                    <Logout sx={{ color: (t) => t.palette.primary.main }} />
                    <Typography fontWeight={500} variant="body2">
                      {data.logout}
                    </Typography>
                  </Stack>
                </CardActionArea>
              </Box>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default DashboardHeader;
